import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField, Button, Checkbox, InputLabel, List, ListItem, ListItemText, Select, MenuItem, FormControl, FormControlLabel, Container, Autocomplete, Grid, Box, Alert, Snackbar, Tooltip, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Divider } from '@mui/material';
import axios from 'axios';
import { AuthContext } from '../../AuthContext';


export const DeviceManagement = (props) =>
{
    const [confirmDelete, setConfirmDelete] = useState(null);
    const [addNewDeviceOpen, setAddNewDeviceOpen] = useState(false);
    const [deviceName, setDeviceName] = useState('');
    const [deviceType, setDeviceType] = useState('');
    const [addingDevice, setAddingDevice] = useState(false);
    const [confirmAddedDevice, setConfirmAddedDevice] = useState(false);
    const [errorDeletingDevice, setErrorDeletingDevice] = useState(false); 
    const [errorDeletingDeviceInUse, setErrorDeletingDeviceInUse] = useState(false);
    const [allUsers, setAllUsers] = useState([]);

    const { user } = useContext(AuthContext);


    useEffect(() => {
      fetchUsers();
    }, []);

    const fetchUsers = () => {
      axios.get('/api/users', { params: { tenantId: props.tenantId } })
        .then(result => {
          // Filter out the logged-in user and users with role CLIENT_USR
          const filteredUsers = result.data.filter(u => u.id !== user.id && u.role === 'CLIENT_USER')
            .map(u => ({
              ...u,
              isResponder: u.isResponder || false,
              isAdmin: u.isAdmin || false
            }));
          setAllUsers(filteredUsers);
          //console.log('Users fetched:', filteredUsers)
        })
        .catch(error => {
          console.error('Error fetching users:', error);
        });
    };
    
    

     // Open and close confirm delete dialog
     const handleConfirmDeleteOpen = (kioskId) => {setErrorDeletingDevice(false); setErrorDeletingDeviceInUse(false); setConfirmDelete(kioskId); }
     const handleConfirmDeleteClose = () => setConfirmDelete(null);

     // Delete kiosk function
     const handleDeleteKiosk = (kioskId) => {
       // API call to delete the kiosk
       axios.delete(`/api/tenants/${kioskId}`).then(response => {
         if (response.data)
         {
           props.setGlobalTenantId(null);
           props.setSelectedKiosk(null);

           //refreshKiosks();
           // Close confirm dialog
           handleConfirmDeleteClose();
           
         }
       }
       ).catch(error => {
         console.error('Error deleting kiosk:', error);
         // Additional error handling logic here
         if (error.response.data.includes('in use'))
         {
           setErrorDeletingDeviceInUse(true);
         }
         else
         {
           setErrorDeletingDevice(true);
         }
       }
       );
     };

     const addResponder = (userId, tenantId, IsKioskAdmin, allowAdhocVideoAccess, AllowMovementNotifications, ReviewVideoMessages, OnlyAllowQRCodeUnlock) => {
       
      axios.post('/api/userResponders', { UserID: userId, TenantID: tenantId, 
          IsKioskAdmin: IsKioskAdmin, 
          AllowAdhocVideoAccess: IsKioskAdmin || allowAdhocVideoAccess,
          AllowMovementNotifications: IsKioskAdmin || AllowMovementNotifications,
          ReviewVideoMessages: IsKioskAdmin || ReviewVideoMessages,
          OnlyAllowQRCodeUnlock: OnlyAllowQRCodeUnlock
        })
      .then(response => {
          console.log('Responder added:', response.data);
      })
      .catch(error => {
          console.error('Error adding responder:', error);
      });
      
  };

     const handleAddKiosk = (e) => {
       // API call to add the kiosk
       e.preventDefault();
       setAddingDevice(true);
       axios.post('/api/tenants', { tenant: {OrganisationName: deviceName, kioskTypeID: deviceType } })
       .then(response => {
         if (response.data)
         {
          let tenantId = response.data.id;
           // call refresh token to get updated allowedtenants
           axios.post('/api/refreshToken').then(response => {

            console.log('Refreshed token:', response.data);

            // Add logic to assign roles to users (applies to CLIENT_USER only)
            allUsers.forEach(user => {
              if (user.isResponder || user.isAdmin) {
                addResponder(user.id, tenantId, user.isAdmin, user.isAdmin, user.isAdmin );
              }
            });

            props.refreshKiosks();

            setDeviceName('');
            setDeviceType(null);

            setAddingDevice(false);

            // Close add dialog
            handleAddDeviceClose();

            // show a dialog saying 'kiosk added successfully' and ask userif they want to switch to it.
            setConfirmAddedDevice(true);
           });
         }
         else
         {
           alert(`Error adding a new device: ${response.data}`);
         }
       }).catch(error => { 
         console.error('Error adding kiosk:', error);
         // Additional error handling logic here
       });
     }
     const handleAddDeviceClose = () => {
       setAddNewDeviceOpen(false);
       props.setSettingsDialogOpen(true);
     }
     const manageNewDevice = () => {
       // set the selected kiosk to the new one
       props.setSelectedKiosk(props.kiosks[props.kiosks.length - 1].id);
       props.setGlobalTenantId(props.kiosks[props.kiosks.length - 1].id);
       // close the dialog
       setConfirmAddedDevice(false);
       props.setSettingsDialogOpen(false);
     }

     const handleRoleChange = (event, index, role) => {
      const updatedUsers = allUsers.map((user, i) => {
        if (i === index) {
          const updatedUser = {
            ...user,
            isResponder: role === 'responder' ? event.target.checked : user.isResponder,
            isAdmin: role === 'admin' ? event.target.checked : (role === 'responder' && !event.target.checked) ? false : user.isAdmin
          };
    
          console.log(`Updated user: ${user.first_name} ${user.last_name}, Responder: ${updatedUser.isResponder}, Admin: ${updatedUser.isAdmin}`);
          return updatedUser;
        }
        return user;
      });
    
      setAllUsers(updatedUsers);
    };
    

return (
        <>
    <Dialog open={addNewDeviceOpen} onClose={handleAddDeviceClose} fullWidth>
        <DialogTitle>Add Device</DialogTitle>
        <DialogContent>
        <form onSubmit={handleAddKiosk}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField 
                    required
                    helperText='Enter a device name'
                    fullWidth label="Device Name" name="deviceName" value={deviceName}
                    onChange={(e) => setDeviceName(e.target.value)} variant="outlined" style={{marginTop: 6}} />
                </Grid>
                <Grid item xs={6}>
                    <FormControl variant="filled" fullWidth='true'>
                        <InputLabel id="kiosk-label">Device type</InputLabel>
                        <Select
                            required
                            value={deviceType}
                            onChange={(e)=>{
                                setDeviceType(e.target.value)
                            }}
                            label="Device type"
                            labelId="devicetype_label"
                            name="KioskTypeID"
                            style={{marginTop: 6}}
                            >
                            {props.kioskTypes.map((kioskType) => (
                                <MenuItem key={kioskType.id} value={kioskType.id}>
                                {kioskType.name}
                                </MenuItem>
                            ))}
                        </Select>
                        
                    </FormControl>
                    
                </Grid>
                    <Typography style={{marginTop: 10, marginLeft: 25, fontSize: '18px', fontWeight: 'bold'}}>Provide access to users</Typography>
                    <Typography style={{marginTop: 10, marginLeft: 25, fontSize: '14px'}}>Select the users you want to provide access to this device. You can change this later. 'Client Admin' users automatically have access and are therefore not listed below.</Typography>
                {allUsers.map((user, index) => (
                  <Grid container item xs={12} key={user.id} alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1">{user.first_name} {user.last_name}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                    <FormControlLabel 
                      control={
                        <Checkbox 
                          checked={user.isResponder || false} // Fallback to false if undefined
                          onChange={(e) => handleRoleChange(e, index, 'responder')}
                        />
                      } 
                      label="Responder" 
                    />
                    </Grid>
                    <Grid item xs={3}>
                    <FormControlLabel 
                      control={
                        <Checkbox 
                          checked={user.isAdmin || false} // Fallback to false if undefined
                          onChange={(e) => handleRoleChange(e, index, 'admin')}
                          disabled={!user.isResponder}
                        />
                      } 
                      label="Admin" 
                    />
                    </Grid>
                  </Grid>
                ))}

            </Grid>
            <DialogActions>
                <Button onClick={handleAddDeviceClose}>Cancel</Button>
                <Button type='submit'>
                {addingDevice ? <CircularProgress size={24} /> : 'Add device'}
                </Button>
            </DialogActions>
        </form>
        </DialogContent>
    </Dialog>
    
    {/* MUI Dialog for adding or editing user */}
    <Dialog onClose={props.handleSettingsClose} open={props.settingsDialogOpen}>
    <DialogTitle>Manage Devices</DialogTitle>
    <DialogContent>
      <List style={{overflowY: 'scroll', scrollbarWidth: 'thin' }}>
        {props.kiosks.map((kiosk, index) => (
          <ListItem key={index} style={{paddingLeft:0}}>
            <ListItemText primary={kiosk.OrganisationName} />
            <Button onClick={() => handleConfirmDeleteOpen(kiosk.id)} variant="outlined"
            disabled={props.kiosks.length <= 1}
            >Delete</Button>
          </ListItem>
        ))}
      </List>
    </DialogContent>
    <DialogActions>
      <Grid container padding={1}>
        <Grid item xs={4}>
          <Button
            disabled={props.kiosks.length >= props.numberOfAvailableKiosks}
            variant='contained'
            onClick={() => {
              fetchUsers();
              setAddNewDeviceOpen(true);
              props.handleSettingsClose();
              }
            }
          >
            Add New Device
          </Button>
        </Grid>
        <Grid item xs={6}>
          {props.kiosks.length < props.numberOfAvailableKiosks &&
          (
            <Typography>You may add up to {props.numberOfAvailableKiosks - props.kiosks.length} more device{props.numberOfAvailableKiosks - props.kiosks.length != 1 && 's'} under your subscription.</Typography>
          )}
          {props.kiosks.length >= props.numberOfAvailableKiosks && (
            <Typography>You have reached the maximum number of devices ({props.numberOfAvailableKiosks}). To add more, upgrade from the Dashboard.</Typography>
          )}
        </Grid>
        <Grid item xs={2}><Button onClick={props.handleSettingsClose}>Close</Button></Grid>
      </Grid>
      
    </DialogActions>
  </Dialog>

  {/* Confirm delete dialog */}
  <Dialog open={Boolean(confirmDelete)} onClose={handleConfirmDeleteClose}>
    <DialogTitle>Confirm Delete</DialogTitle>
    <DialogContent>Are you sure you want to delete this device and all associated data?

      {errorDeletingDevice && (<Typography style={{color: 'red', marginTop: 20}}>
          Sorry, we were unable to delete the device. This is because it has associated data such as PINs or number plates. Please delete these first.
        </Typography>)}
      {errorDeletingDeviceInUse && (<Typography style={{color: 'red', marginTop: 20}}>
        Sorry, we were unable to delete the device because it's currently in use on a live kiosk, or has been in the last 10 minutes. First log off the kiosk, force
        the app to close or power off the kiosk and try again.
      </Typography>)}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleConfirmDeleteClose}>Cancel</Button>
      {!errorDeletingDevice && !errorDeletingDeviceInUse && (<Button onClick={() => handleDeleteKiosk(confirmDelete)}>Confirm</Button>)}
    </DialogActions>
  </Dialog>

  <Dialog open={confirmAddedDevice} onClose={()=> {setConfirmAddedDevice(false)}} fullWidth>
    <DialogTitle>Add Device</DialogTitle>
    <DialogContent>
      <Typography>Device added successfully. Do you want to manage this device now?</Typography>
      <DialogActions>
            <Button onClick={()=>{manageNewDevice()}}>OK</Button>
            <Button onClick={()=>{setConfirmAddedDevice(false)}}>Close</Button>
          </DialogActions>
      </DialogContent>
    </Dialog>
    </>
    )
}