import React from 'react';
import { 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeBlock from '../CodeBlock';

export const settings = (
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="settings-content"
            id="settings-header"
        >
            <Typography variant="h6">Tenant Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Get Tenant Settings
            </Typography>
            <CodeBlock code={`GET /api/tenants?tenantId=your_tenant_id

// Response
{
    // Basic Information
    "id": 2,                              // Unique identifier for the tenant
    "OrganisationName": "10 Tree Lane",   // Display name for the tenant/device
    "SettingGateNomenclature": "Gate",    // Custom name for entry point (e.g., "Gate", "Door", "Barrier")
    "SettingGateText": null,              // Custom text for gate-related messages
    "SettingGatePhoneNumber": null,       // Phone number associated with the gate
    
    // Gate Control Settings
    "SettingSendSMSToOpenGate": false,    // Enable SMS-based gate control
    "SettingCallToOpenGate": false,       // Enable phone call-based gate control
    "SettingGateOpeningText": "{Gate} opening...", // Message shown when gate is opening
    
    // Core UI Text Settings
    "SettingTitleText": "Welcome!",       // Main welcome message
    "SettingVisitorButtonText": "Visitor", // Visitor button label
    "SettingDeliveryButtonText": "Delivery / Collection", // Delivery button label
    "SettingPINButtonText": "I have a PIN", // PIN entry button label
    "SettingChooseFromOptionsText": "Choose from the following options:", // Menu instructions
    "SettingCCTVText": "CCTV IN OPERATION", // CCTV notice
    "SettingPoweredByText": "Powered By",  // Footer text
    "SettingCancelButtonText": "Cancel",   // Cancel button text
    "SettingPleaseChooseText": "Please choose...", // Selection prompt
    "SettingConnectingText": "Connecting...", // Connection status
    "SettingYouAreConnectedText": "You are connected", // Connected status
    "SettingIncludeVisitorButton": true,  // Show visitor button

    // Delivery Options
    "SettingIncludeDeliveryButton": true,  // Show delivery button
    "SettingSecondLevelDeliveryOption": true, // Enable two-step delivery process
    "SettingDeliveryButtonNoSpeakText": "Drop off / collect package", // Direct entry option
    "SettingDeliveryButtonSpeakText": "Speak to home owner", // Contact owner option
    
    // PIN Settings
    "SettingIncludePINSButton": false,    // Show PIN entry option
    "SettingEnterPINText": "Enter your PIN", // PIN entry prompt
    "SettingInvalidPINText": "Invalid PIN", // Invalid PIN message
    "SettingAllowAliasesForPINs": true,   // Enable PIN aliases
    "SettingWelcomeVisitorText": "Welcome {name}. {Gate} opening...", // Success message
    
    // Communication Settings
    "SettingWhoSpeakText": "Who do you want to notify?", // Responder selection
    "SettingAllowKioskResponderChoice": true, // Enable responder selection
    "SettingAllowKioskAllResponderChoice": true, // Allow notifying all responders
    "SettingSendAllGateNotificationsToKioskAdmins": true, // Copy admins on notifications
    "SettingCallToNotify": false,         // Enable call notifications
    "SettingNotifyNumber": "+44",         // Default country code
    "SettingNotifyTimeoutSeconds": 6,     // Notification timeout
    
    // Video Message Settings
    "SettingAllowVoicemail": true,        // Enable video messages
    "SettingVideoMessageWaitSeconds": 2,   // Recording delay
    "SettingNobodyAnsweringText": "Nobody answering?", // No response prompt
    "SettingLeaveMessageText": "Press here to leave a message", // Message button
    "SettingRecordingStartedText": "Recording started", // Recording status
    "SettingSaveAndSendText": "Save and send", // Send button
    "SettingStartAgainText": "Start again", // Restart button
    "SettingMessageSentText": "Message sent. Sorry we were unable to open the {gate} today. Please come back another time.",
    
    // ANPR Settings
    "SettingEnableANPR": false,           // Enable plate recognition
    "SettingANPRWaitTimeMilliseconds": 5000, // ANPR capture delay
    "SettingANPRMessage": "Automatic Number Plate Recognition In Progress...",
    "SettingANPRWelcomeText": "Welcome {name}!", // ANPR welcome
    "SettingANPRAuthorisingText": "Authorising - please wait...",
    "SettingAllowAliasesForNumberPlates": true, // Enable plate aliases
    "SettingANPRUnrecognisedVehicleWorkflow": false, // Handle unknown plates
    "SettingANPRUnrecognisedNotificationOption": 1, // Unknown plate notification
    "SettingANPRSecondsBeforeReads": 300, // Time between reads
    "SettingMirrorANPRWelcomeMessageOnTenantId": 3, // Mirror ANPR messages
    
    // Camera & Movement
    "SettingUseRearCamera": false,        // Use rear camera
    "SettingEnableSnapshots": false,      // Enable snapshots
    "SettingSnapshotFrequencySeconds": 600, // Snapshot interval
    "SettingKeepSnapshotsForDays": 1,     // Snapshot retention
    "SettingEnableMovementDetection": true, // Enable motion detection
    "SettingEnableMovementVideo": false,   // Record motion video
    "SettingMovementNotificationWaitSeconds": 5, // Motion notification delay
    "SettingUseExternalANPRCamera": false, // Use external ANPR camera
    "SettingExternalANPRCameraURL": null, // URL of external ANPR camera
    
    // QR Code Settings
    "SettingEnableQRCodeKeys": true,      // Enable QR codes
    "SettingIncludeQRCodeKeyButton": true, // Show QR scan button
    "SettingQRCodeButtonText": "Scan QR Code", // Scan button text
    "SettingQRCodeScanText": "Scan your QR code", // Scan instructions
    "SettingQRCodePositionText": "Position your QR code in the frame", // Position guide
    "SettingQRCodeKeysSecondsBeforeReads": 20, // Time between scans
    "SettingAllowAliasesForQrCodeKeys": false, // Enable QR aliases
    
    // Entry Instructions
    "SettingShowEntryInstructionsVisitors": false, // Show visitor instructions
    "SettingShowEntryInstructionsDeliveries": false, // Show delivery instructions
    "SettingEntryInstructionsVisitors": null, // Custom visitor instructions
    "SettingEntryInstructionsDeliveries": null, // Custom delivery instructions
    
    // Timeouts
    "SettingDeliveryOrVisitorMessageTimeoutSeconds": 10, // Message display time
    "SettingNonInteractiveWelcomeTimeoutSeconds": 15, // Welcome screen timeout
    
    // Theme & UI
    "SettingCustomKioskUI": true,         // Enable custom UI
    "SettingKioskUI": "...",              // Custom UI HTML template
    "SettingCustomKioskTheme": "...",     // Custom CSS theme
    "SettingCustomKioskThemeBackgroundImageURL": "/images/relaxed-background.png",
    "SettingCustomKioskThemeLandscapeBackgroundImageURL": "/images/relaxed-background-landscape.png",
    "SettingCustomKioskThemeColour1": "#000F0E", // Primary color
    "SettingCustomKioskThemeColour2": "#FFFFFF", // Secondary color
    "SettingCustomKioskThemeColour3": "#EF5B24", // Accent color
    "SettingCustomKioskThemeColour4": "#000F0E", // Additional theme colors
    "SettingCustomKioskThemeColour5": "#000F0E",
    "SettingCustomKioskThemeColour6": "#000F0E",
    
    // System Settings
    "securityLevel": 10,                 // Access control level
    
    // Timestamps
    "createdAt": "2024-02-22T17:06:18.371Z",
    "updatedAt": "2025-01-09T14:08:27.537Z"
}`} />

            <Typography variant="subtitle1" sx={{ mt: 2 }} gutterBottom>
                Important Notes:
            </Typography>
            <Box sx={{ pl: 2, mb: 2 }}>
                <Typography variant="body2" sx={{ display: 'list-item' }} color="text.secondary">
                    Text fields support placeholders: 'name' and 'gate' in curly brackets are replaced at runtime
                </Typography>
                <Typography variant="body2" sx={{ display: 'list-item' }} color="text.secondary">
                    Movement sensitivity settings (10000000-40000000) affect network traffic
                </Typography>
                <Typography variant="body2" sx={{ display: 'list-item' }} color="text.secondary">
                    Custom UI (SettingKioskUI) and theme (SettingCustomKioskTheme) fields contain extensive HTML/CSS
                </Typography>
                <Typography variant="body2" sx={{ display: 'list-item' }} color="text.secondary">
                    All timeout values are in seconds unless specified as milliseconds
                </Typography>
            </Box>

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Update Tenant Settings
            </Typography>
            <CodeBlock code={`PUT /api/tenants?tenantId=your_tenant_id
{
    "OrganisationName": "Main Entrance",
    "SettingCustomKioskThemeBackgroundImageURL": 'http://...',
    // ... any other settings to update
}

// Response: Updated tenant object`} />

          
            
        </AccordionDetails>
    </Accordion>
); 