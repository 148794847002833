import React from 'react';
import { 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeBlock from '../CodeBlock';

export const webhooks = (
    <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="webhooks-content"
            id="webhooks-header"
        >
            <Typography variant="h6">Webhooks</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography variant="body2" paragraph>
                Webhooks allow you to receive real-time notifications about events in your Entrinsic system. When configured events occur, 
                our system will send HTTP POST requests to your specified endpoint with event details.
            </Typography>

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Setting Up Webhooks
            </Typography>
            <Typography variant="body2" paragraph>
                <ol>
                    <li>Navigate to Developers → Configure Webhooks from the main menu</li>
                    <li>Click the "+" button to add a new webhook</li>
                    <li>Enter a name and the URL where you want to receive webhook events</li>
                    <li>Select which events you want to subscribe to</li>
                    <li>Save your webhook secret key - you'll need this to verify webhook authenticity</li>
                </ol>
            </Typography>

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Receiving Webhooks
            </Typography>
            <Typography variant="body2" paragraph>
                Webhooks are sent as HTTP POST requests to your specified URL. Each request includes:
            </Typography>
            <CodeBlock code={`// Headers sent with each request
{
    'Content-Type': 'application/json',
    'X-Signature': 'sha256=<HMAC signature>',
    'User-Agent': 'Entrinsic-Webhook/1.0',
    'X-Webhook-ID': '123',
    'X-Attempt': '1'
}`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Verifying Webhooks
            </Typography>
            <Typography variant="body2" paragraph>
                To ensure the webhook came from Entrinsic, verify the signature using your webhook secret. Here's some example code in NodeJs to illustrate:
            </Typography>
            <CodeBlock code={`// Example webhook verification (Node.js/Express)
const crypto = require('crypto');
const express = require('express');
const app = express();

app.post('/webhook', express.json(), (req, res) => {
    const signature = req.headers['x-signature'];
    const payload = req.body;
    const secret = 'your_webhook_secret';

    // Verify signature
    const expectedSignature = crypto
        .createHmac('sha256', secret)
        .update(JSON.stringify(payload))
        .digest('hex');
    
    if (!crypto.timingSafeEqual(
        Buffer.from(signature.replace('sha256=', '')),
        Buffer.from(expectedSignature)
    )) {
        return res.status(401).send('Invalid signature');
    }

    // Handle the event
    switch(payload.event) {
        case 'QR-Read-Open':
            console.log(\`Gate opened for QR code: \${payload.data.qrCode}\`);
            break;
        case 'Number-Plate-Read':
            console.log(\`Plate detected: \${payload.data.plateNumber}\`);
            break;
        // ... handle other events
    }

    // Acknowledge receipt
    res.status(200).send('OK');
});`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Event Types
            </Typography>
            <Typography variant="body2" paragraph>
                Available events you can subscribe to:
            </Typography>
            <CodeBlock code={`// Available Event Types
{
    "QR-Read-Open": "QR code was successfully read and gate opened",
    "QR-Read-Fail": "QR code read failed",
    "Gate-Open": "Gate was opened",
    "Number-Plate-Read": "Number plate was successfully read",
    "Number-Plate-Read-Fail": "Number plate was read but rejected",
    "Number-Plate-Unrecognised": "Number plate was seen but unrecognised",
    "PIN-Entry": "PIN was successfully entered",
    "PIN-Fail": "PIN entry failed",
    "Movement-Detection": "Movement was detected and a snapshot is available to download",
    "Movement-Video-Created": "A movement video was created and available to download",
    "Visitor-At-Gate": "A visitor or delivery is calling at the gate/door/barrier",
    "Visitor-Cancelled": "A visitor or delivery has cancelled the call",
    "Visitor-Timeout": "A visitor or delivery has not been responded to after a period of time",
    "User-Responded-To-Visitor": "A user has responded to a visitor or delivery"
}`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Event Payloads
            </Typography>
            <Typography variant="body2" paragraph>
                Example payloads for different event types:
            </Typography>
            <CodeBlock code={`// PIN-Entry event payload
{
    "event": "PIN-Entry",
    "timestamp": "2024-01-01T12:00:00.000Z",
    "data": {
        "pin": "1234",          // The PIN that was entered
        "name": "John Smith",   // Name associated with the PIN
        "tenantId": "123",      // Tenant ID where PIN was used
    }
}

// PIN-Fail event payload
{
    "event": "PIN-Fail",
    "timestamp": "2024-01-01T12:00:00.000Z",
    "data": {
        "pin": "1234",          // The incorrect PIN that was entered
        "tenantId": "123",      // Tenant ID where attempt was made
    }
}

// QR-Read-Open event payload
{
    "event": "QR-Read-Open",
    "timestamp": "2024-01-01T12:00:00.000Z",
    "data": {
        "qrCodeKeyId": "789",   // ID of the QR code key
        "name": "Emily Smith", // Name associated with the QR code
        "tenantId": "123",      // Tenant ID where scan occurred
        "aliasId": 123, // the alias ID associated with the plate
        "mobileNumber": "07111 1111111", // mobile number of QR code key
    }
}


// QR-Read-Fail event payload
{
    "event": "QR-Read-Fail",
    "timestamp": "2024-01-01T12:00:00.000Z",
    "data": {
        "qrCodeKeyId": "789",   // ID of the QR code key
        "name": "Emily Smith",  // Name associated with the QR code
        "tenantId": "123",      // Tenant ID where scan occurred
        "message": null         // will be returned in the event of failure.
    }
}

// Gate-Open event payload  
{
    "event": "Gate-Open",
    "timestamp": "2025-01-14T17:53:17.860Z",
    "data": {
        "name": "Emily Smith with plate AB1 1AA",
        "tenantId": 6,
        }
}

// Number-Plate-Read event payload
{
    "event": "Number-Plate-Read",
    "timestamp": "2025-01-14T17:47:58.798Z",
    "data": {
        "plate": "AB1 1AA", // the recognised plate number
        "name": "Emily Smith", // the name associated with the plate
        "numberPlateId": 123, // the number plate ID
        "tenantId": 6, // the tenant ID where the plate was read
        "aliasId": 123, // the alias ID associated with the plate
        "userIdConfirmation": null, // if a userId is associated for 2-factor confirmation
        "mobileConfirmationNumber": null, // if a mobile number is associated for 2-factor confirmation
    }
}
    
// Number-Plate-Read event payload
{
    "event": "Number-Plate-Read-Fail",
    "timestamp": "2025-01-14T17:47:58.798Z",
    "data": {
        "message": "Number plate not valid at this time", // the message associated with the failure
        "plate": "AB1 1AA", // the recognised plate number
        "name": "Emily Smith", // the name associated with the plate
        "numberPlateId": 123, // the number plate ID
        "tenantId": 6, // the tenant ID where the plate was read
        "aliasId": 123, // the alias ID associated with the plate
        "userIdConfirmation": null, // if a userId is associated for 2-factor confirmation
        "mobileConfirmationNumber": null, // if a mobile number is associated for 2-factor confirmation
    }
}
    

// Number-Plate-Unrecognised event payload
{
    "event": "Number-Plate-Unrecognised",
    "timestamp": "2025-01-14T18:02:35.778Z",
    "data": {
        "tenant": "Test Account",               // the tenant name
        "plate": "AJ70AEM",                     // the unrecognised plate number
        "enterUnrecognisedWorkflow": true       // true if the tenant has the setting ANPRUnrecognisedVehicleWorkflow enabled
    }
}

// Movement-Detection event payload
{
    "event": "Movement-Detection",
    "timestamp": "2024-03-14T10:30:00.000Z",
    "data": {
        "tenantId": 123,        // The ID of the tenant where movement was detected
        "imageUrl": "https://..." // URL to the captured snapshot image
    }
}

// Movement-Video-Created event payload
{
    "event": "Movement-Video-Created",
    "timestamp": "2024-03-14T10:30:00.000Z",
    "data": {
        "tenantId": 123,        // The ID of the tenant where video was uploaded from
        "url": "https://..."    // URL to the captured video
    }
}

// Visitor-At-Gate event payload
{
    "event": "Visitor-At-Gate",
    "timestamp": "2025-02-06T18:28:07.067Z",
    "data": {
        "tenantId": 123,        // The ID of the tenant being visited
        "type": "visitor",       // Type of visitor (values are visitor, visitor_delivery or visitor_delivery_nospeak)
        "aliasId": 123,          // the alias ID associated with the plate
    }
}

// Visitor-Cancelled event payload
{
    "event": "Visitor-Cancelled",
    "timestamp": "2025-02-10T17:39:28.701Z",
    "data": {
        "tenantId": 123        // The ID of the tenant where the call was cancelled
    }
}

// Visitor-Timeout event payload
{
    "event": "Visitor-Timeout",
    "timestamp": "2025-02-10T19:52:22.804Z",
    "data": {
        "tenantId": 123        // The ID of the tenant where the timeout occurred
    }
}

// User-Responded-To-Visitor event payload
{
    "event": "User-Responded-To-Visitor",
    "timestamp": "2025-02-11T08:07:41.087Z",
    "data": {
        "tenantId": 123,        // The ID of the tenant being responded to
        "userId": 123,           // The ID of the user who responded
        "user": "John Smith"     // The name of the user who responded
    }
}`} />

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Best Practices
            </Typography>
            <Typography variant="body2" component="div">
                <ul>
                    <li>Always verify the webhook signature</li>
                    <li>Respond quickly (within 5 seconds) to webhook requests</li>
                    <li>Handle duplicate events (we may retry failed deliveries)</li>
                    <li>Store the webhook secret securely</li>
                    <li>Monitor the webhook logs in the Entrinsic Developer section of the web portal for delivery issues</li>
                </ul>
            </Typography>

            <Typography variant="subtitle1" sx={{ mt: 3 }}>
                Interactive Webhooks
            </Typography>
            <Typography variant="body2" paragraph>
                Some events support interactive webhooks, which require a verification response. These webhooks are used
                when you need to approve or deny access through a 3rd party system in real-time. Your endpoint must respond within 5 seconds, and
                the response must include a 'verified' field. In the event of a declined entry (verified = false), you can also include a 'message' field which is displayed to the user on the kiosk.
                
            </Typography>

            <Typography variant="body2" paragraph>
                Interactive webhook events:
            </Typography>
            <CodeBlock code={`// Interactive Event Types
{
    "QR-Read-Verify": "Verify QR code access before gate opens",
    "PIN-Verify": "Verify PIN access before gate opens",
    "Number-Plate-Verify": "Verify number plate access before gate opens"
}`} />

            <Typography variant="body2" paragraph>
                Example interactive webhook flow:
            </Typography>
            <CodeBlock code={`// 1. Entrinsic sends verification request
{
    "event": "QR-Read-Verify",
    "timestamp": "2024-01-01T12:00:00.000Z",
    "data": { 
        "qrCodeKeyId": "789",
        "name": "Emily Smith",
        "tenantId": "123"
    }
}

// Example: Number-Plate-Verify request
{
    "event": "Number-Plate-Verify",
    "timestamp": "2024-01-01T12:00:00.000Z",
    "data": {
        "numberPlateId": "123",
        "plate": "ABC123",
        "name": "John Smith",
        "tenantId": "123",
        "timestamp": "2024-01-01T12:00:00.000Z",
        "snapshotImage": "https://res.cloudinary.com/..."  // Cloudinary URL to the snapshot image taken at the time of the number plate read
    }
}

// 2. Your endpoint must respond with a verification status
// Example: Access Granted
{
    "verified": true,
    "message": "User access revoked",               // Optional - this appears on the kiosk after verification
    "metadata": {                                   // Optional - any additional information in the response is logged against the QR Code Key Log
        "accessLevel": "contractor",
        "validatedBy": "security-system-a"
    }
}

// Example: Access Denied
{
    "verified": false,
    "message": "User access revoked",               // Optional - this appears on the kiosk after verification
    "metadata": {                                   // Optional - any additional information in the response is logged against the QR Code Key Log
        "reason": "contract-expired",
        "expiryDate": "2024-01-01"
    }
}`} />

            <Typography variant="body2" sx={{ mt: 2 }} color="text.secondary">
                Notes about interactive webhooks:
                <ul>
                    <li>Must respond within 5 seconds</li>
                    <li>Response must include a 'verified' boolean field</li>
                    <li>Additional response fields are logged but not required</li>
                    <li>No retries if response is HTTP 200</li>
                    <li>Will retry up to 3 times for non-200 responses</li>
                    <li>For Number-Plate-Verify events, a snapshot image may be included if available</li>
                </ul>
            </Typography>
        </AccordionDetails>
    </Accordion>
); 