import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Layout from '../../components/Layout';
import { TextField, Button, Checkbox, Autocomplete, FormControlLabel, Dialog, DialogActions, DialogContent, DialogTitle, Container, Grid, Box, Alert, Snackbar, Tooltip, Typography, useTheme, useMediaQuery, Card, CardContent, Stack, Accordion, AccordionSummary, AccordionDetails, Chip, AlertTitle, FormControl, InputLabel, Select, MenuItem, FormHelperText, Radio, IconButton, Menu, ListItemIcon, ListItemText } from '@mui/material';
import { AccessTime, CalendarMonth, ExpandMore as ExpandMoreIcon, FiberManualRecord, Download as DownloadIcon, MoreVert as MoreVertIcon, Edit as EditIcon, DeleteOutline as DeleteIcon, Send as SendIcon, History as HistoryIcon } from '@mui/icons-material';
import axios from 'axios';
import { AuthContext } from '../../AuthContext';

const QrCodeKeysGrid = () => {
    const [rows, setRows] = useState([]);
    const [gridHeight, setGridHeight] = useState(400);  // default value
    const [loadingAddOrUpdate, setLoadingAddOrUpdate] = useState(false);
    const [deletingIds, setDeletingIds] = useState(new Set());
    const [gridLoading, setGridLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { globalTenantId } = useContext(AuthContext);
    const [shareQrCodeKeys, setShareQrCodeKeys] = useState(false);
    const [sharingMasterTenantID, setSharingMasterTenantID] = useState(null);
    const [sharingMasterTenantName, setSharingMasterTenantName] = useState('');
    const [responderAliases, setResponderAliases] = useState([]);
    const [showAliases, setShowAliases] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [allUsers, setAllUsers] = useState([]); // To hold all available users
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [enableDateRestriction, setEnableDateRestriction] = useState(false);
    const [enableTimeRestriction, setEnableTimeRestriction] = useState(false);
    const [restrictionsExpanded, setRestrictionsExpanded] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [sendKeyDialogOpen, setSendKeyDialogOpen] = useState(false);
    const [selectedKeyToSend, setSelectedKeyToSend] = useState(null);
    const [sendImmediately, setSendImmediately] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [sendingKey, setSendingKey] = useState(false);
    const [qrEnabled, setQREnabled] = useState(null);
    const [logDialogOpen, setLogDialogOpen] = useState(false);
    const [selectedQrCodeKey, setSelectedQrCodeKey] = useState(null);
    const [logs, setLogs] = useState([]);
    const [logCount, setLogCount] = useState(0);
    const [logLoading, setLogLoading] = useState(false);
    const [logPaginationModel, setLogPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });
    const [logSortModel, setLogSortModel] = useState([{
        field: 'createdAt',
        sort: 'desc',
    }]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25,
        page: 0
    });
    const [rowCount, setRowCount] = useState(0);
    const [logSearchTerm, setLogSearchTerm] = useState('');
    const [logSearchTimeout, setLogSearchTimeout] = useState(null);
    const [allTenants, setAllTenants] = useState([]);
    const [selectedOverrideTenants, setSelectedOverrideTenants] = useState([]);
    const [kioskRestrictionsExpanded, setKioskRestrictionsExpanded] = useState(false);
    const [showAccessWarning, setShowAccessWarning] = useState(false);
    const [accessWarningMessage, setAccessWarningMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');
    const [showWarningAlert, setShowWarningAlert] = useState(false);
    const [accessibleKiosks, setAccessibleKiosks] = useState([]);
    const [qrCodeType, setQrCodeType] = useState('standard');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        if (!globalTenantId) return;   
        fetchData();
        fetchResponderAliases(); 
        fetchUsers(); // Fetch users
        fetchQRStatus();
        fetchTenants(); // Add this line

    }, [globalTenantId]);
    
    useEffect(() => {
        const viewportHeight = window.innerHeight;
        const calculatedHeight = viewportHeight - 300;
        
        setGridHeight(calculatedHeight);
    }, []); 
    
    // State for form inputs
    const [form, setForm] = useState({
        name: '', responderAliasId: null, internalName: '', mobileNumber: '', startDate: '', endDate: '', timeOfDayStart: '', timeOfDayEnd: '', active: true, autoDeleteOnExpiry: false, securityLevel: '', overrideTenantIds: [], linkedUserId: null
    });
    
    const fetchResponderAliases = () => {
//console.log('tenantid', sharingMasterTenantID || globalTenantId, sharingMasterTenantID, globalTenantId)
        axios.get(`/api/responder-aliases-isenabled`,  { params: { tenantId: sharingMasterTenantID || globalTenantId } })
        .then(response => {
            if (response.data.SettingAllowAliasesForQrCodeKeys === true) {
                setShowAliases(true);
            }
            else {
                setShowAliases(false);
            }
        })

        axios.get('/api/responder-aliases-kiosk',  { params: { tenantId: sharingMasterTenantID || globalTenantId } })
        .then(response => {
            setResponderAliases(response.data);
        
        })
        .catch(error => console.error('Error fetching responder aliases:', error));
    };

    const fetchUsers = () => {
        axios.get('/api/users', { params: { tenantId: sharingMasterTenantID || globalTenantId } })
        .then(result => {
          setAllUsers(result.data);
        })
        .catch(error => {
          console.error('Error fetching users:', error);
        });
      };

    // Function to handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm(prevForm => ({
            ...prevForm,
            [name]: value,
        }));
    };

    // Create a copy of the form data for submission
    const prepareSubmitData = (formData) => {
        return {
            ...formData,
            timeOfDayStart: enableTimeRestriction ? formData.timeOfDayStart : null,
            timeOfDayEnd: enableTimeRestriction ? formData.timeOfDayEnd : null,
            startDate: enableDateRestriction ? (formData.startDate || null) : null,
            endDate: enableDateRestriction ? (formData.endDate || null) : null
        };
    };

    // Function to handle form submission
    const handleAddSubmit = async (e) => {
        e.preventDefault();
        
        // Validate based on QR code type
        if (qrCodeType === 'standard') {
            // Validation for standard QR codes
            if (!form.name.trim()) {
                setErrorMessage('Welcome Name is required');
                setShowAlert(true);
                return;
            }

            if (!form.mobileNumber.trim()) {
                setErrorMessage('Mobile Number is required');
                setShowAlert(true);
                return;
            }
        } else {
            // Validation for user-linked QR codes
            if (!form.linkedUserId) {
                setErrorMessage('Please select a user');
                setShowAlert(true);
                return;
            }
        }

        // Validate date restrictions
        if (enableDateRestriction) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            if (!form.startDate && !form.endDate) {
                setErrorMessage('When date restriction is enabled, you must specify at least a start date or end date');
                setShowAlert(true);
                return;
            }

            if (form.endDate) {
                const endDate = new Date(form.endDate);
                if (endDate < today) {
                    setErrorMessage('End date cannot be in the past');
                    setShowAlert(true);
                    return;
                }
            }
        }

        // Validate time restrictions
        if (enableTimeRestriction && (!form.timeOfDayStart || !form.timeOfDayEnd)) {
            setErrorMessage('When time restriction is enabled, both start and end times are required');
            setShowAlert(true);
            return;
        }

        const submitData = prepareSubmitData(form);

        setLoadingAddOrUpdate(true);
        try {
            const response = await axios.post('/api/qr-code-keys', {
                ...submitData,
                sendImmediately: sendImmediately
            }, {
                params: { tenantId: sharingMasterTenantID || globalTenantId }
            });
            
            if (response.data) {
                if (response.data.warning) {
                    // Show warning message when SMS fails but QR code was created
                    setWarningMessage('QR Code Key created but SMS sending failed');
                    setShowWarningAlert(true);
                } else {
                    // Show success message for complete success
                    setSuccessMessage('QR Code Key created successfully');
                    setShowSuccessAlert(true);
                }
            }
            
            setDialogOpen(false);
            setLoadingAddOrUpdate(false);
            resetForm();
            fetchData();
        } catch (error) {
            setLoadingAddOrUpdate(false);
            setErrorMessage(error.response?.data || 'Failed to add QR Code Key');
            setShowAlert(true);
        }
    };

    const fetchData = () => {
        setGridLoading(true);
        setShowAccessWarning(false); // Reset warning state

        // get the share QR Code Keys setting
        axios.get('/api/qr-code-keys/is-shared', { params: { tenantId: globalTenantId } })
        .then(result => {
            let shareId = null;
            setShareQrCodeKeys(result.data.ShareQrCodeKeys);
            if (result.data.ShareQrCodeKeys) {
                shareId = result.data.SharingQrCodeKeysMasterTenantID; 
                setSharingMasterTenantID(shareId);
                setSharingMasterTenantName(result.data.masterTenantName);
            }
            else {
                setSharingMasterTenantID(null);
            }
            
            // get the QR Code Keys either from the shared tenant or the global one if sharing is not enabled
            axios.get('/api/qr-code-keys', { 
                params: { 
                    tenantId: shareId || globalTenantId,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                    search: searchTerm
                } 
            })
            .then(result => {
                setGridLoading(false);
                setRows(result.data.rows);  // Update to use .rows
                setRowCount(result.data.count);  // Set the total count
            })
            .catch(error => {
                setGridLoading(false);
                if (error.response?.data?.includes('Not allowed to access this tenant')) {
                    setShowAccessWarning(true);
                    setAccessWarningMessage(`This tenant has shared QR Code Keys from another tenant to which you don't have access. Please contact your administrator.`);
                    setRows([]); // Clear any existing data
                    setRowCount(0); // Reset row count
                }
                console.error('Error fetching data:', error);
            });
        })
        .catch(error => {
            setGridLoading(false);
            console.error('Error fetching data:', error);
        });
    };

    // Add useEffect to refetch when pagination or search changes
    useEffect(() => {
        if (globalTenantId) {
            fetchData();
        }
    }, [globalTenantId, paginationModel.page, paginationModel.pageSize, searchTerm]);

    // State for editing mode and edited entry
    const [isEditing, setIsEditing] = useState(false);
    const [editedEntry, setEditedEntry] = useState(null);

    // Function to handle editing
    const handleEditClick = async (entry) => {
        // Format dates from ISO string to YYYY-MM-DD format
        const formatDate = (dateString) => {
            if (!dateString) return '';
            return new Date(dateString).toISOString().split('T')[0];
        };

        // Format times from database format to HH:mm format
        const formatTime = (timeString) => {
            if (!timeString) return '';
            const match = timeString.match(/(\d{1,2}):(\d{2})/);
            if (!match) return '';
            
            const hours = match[1].padStart(2, '0');
            const minutes = match[2];
            
            return `${hours}:${minutes}`;
        };

        setForm({ 
            name: entry.name, 
            responderAliasId: entry.responderAliasId,
            internalName: entry.internalName,
            mobileNumber: entry.mobileNumber || '',
            startDate: formatDate(entry.startDate),
            endDate: formatDate(entry.endDate),
            timeOfDayStart: formatTime(entry.timeOfDayStart),
            timeOfDayEnd: formatTime(entry.timeOfDayEnd),
            active: entry.active,
            autoDeleteOnExpiry: entry.autoDeleteOnExpiry || false,
            securityLevel: entry.securityLevel || '',
            linkedUserId: entry.linkedUserId || null
        });

        setQrCodeType(entry.linkedUserId ? 'user' : 'standard');
        
        // Set the checkboxes based on whether dates/times exist
        setEnableDateRestriction(!!(entry.startDate || entry.endDate));
        setEnableTimeRestriction(!!(entry.timeOfDayStart || entry.timeOfDayEnd));
        
        // Set expanded states
        setRestrictionsExpanded(!!(entry.startDate || entry.endDate || entry.timeOfDayStart || entry.timeOfDayEnd));
        
        setEditedEntry(entry);
        setIsEditing(true);
        setDialogOpen(true);

        try {
            const overridesResponse = await axios.get(`/api/qr-code-keys/${entry.id}/overrides`, {
                params: { tenantId: sharingMasterTenantID || globalTenantId }
            });

            const overrides = overridesResponse.data;
            const selectedTenants = overrides.map(override => 
                allTenants.find(tenant => tenant.id === override.tenantId)
            ).filter(Boolean);

            setSelectedOverrideTenants(selectedTenants);
            setForm(prev => ({
                ...prev,
                overrideTenantIds: overrides.map(o => o.tenantId)
            }));

            // Set accordion expanded if there are overrides or security level
            
            setKioskRestrictionsExpanded(
                selectedTenants.length > 0 || entry.securityLevel !== null
            );

            setIsEditing(true);
            setEditedEntry(entry);
            setDialogOpen(true);

        } catch (error) {
            console.error('Error fetching overrides:', error);
            setErrorMessage('Failed to fetch kiosk access restrictions');
            setShowAlert(true);
        }
    };

    // Function to handle cancel action
    const handleCancelEdit = () => {
        setDialogOpen(false);
        resetForm();// Reset the form to its initial state
        setIsEditing(false); // Exit editing mode
        setEditedEntry(null); // Clear edited entry
        setQrCodeType('standard');
    };
    
    // Function to handle update submission
    const handleUpdateSubmit = (e) => {
        e.preventDefault();
        
        // Only validate these fields if it's not a user-linked QR code
        if (!form.linkedUserId) {
            if (!form.name.trim()) {
                setErrorMessage('Welcome Name is required');
                setShowAlert(true);
                return;
            }

            if (!form.mobileNumber.trim()) {
                setErrorMessage('Mobile Number is required');
                setShowAlert(true);
                return;
            }
        }

        // Validate date restrictions
        if (enableDateRestriction) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            if (!form.startDate && !form.endDate) {
                setErrorMessage('When date restriction is enabled, you must specify at least a start date or end date');
                setShowAlert(true);
                return;
            }

            if (form.endDate) {
                const endDate = new Date(form.endDate);
                if (endDate < today) {
                    setErrorMessage('End date cannot be in the past');
                    setShowAlert(true);
                    return;
                }
            }
        }

        // Validate time restrictions
        if (enableTimeRestriction && (!form.timeOfDayStart || !form.timeOfDayEnd)) {
            setErrorMessage('When time restriction is enabled, both start and end times are required');
            setShowAlert(true);
            return;
        }

        const submitData = prepareSubmitData(form);

        setLoadingAddOrUpdate(true);
        axios.put(`/api/qr-code-keys/${editedEntry.id}`, submitData, { params: { tenantId: sharingMasterTenantID || globalTenantId } })
            .then(result => {
                setDialogOpen(false);
                setLoadingAddOrUpdate(false);
                resetForm();
                setIsEditing(false);
                setEditedEntry(null);
                fetchData();
            })
            .catch(error => {
                setLoadingAddOrUpdate(false);
                setErrorMessage(error.response.data);
                setShowAlert(true);
            });
    };

    const handleShareQrCodeKeyChange = (event) => {
        setShareQrCodeKeys(event.target.checked);

        // update the share qrCodeKeys setting
        axios.post(`/api/qr-code-keys/is-shared?tenantId=${globalTenantId}`, { ShareQrCodeKeys: event.target.checked })
        .then(result=> {
            // do nothing
            fetchData();
        })
        .catch(error => {
            console.error('Error updating share qrCodeKeys setting:', error);
        });
    };

    const handleDelete = (id) => {
        setDeletingIds(prevDeletingIds => {
            const newDeletingIds = new Set(prevDeletingIds);
            newDeletingIds.add(id);
            return newDeletingIds;
        });

        axios.delete(`/api/qr-code-keys/${id}`, { 
            params: { tenantId: sharingMasterTenantID || globalTenantId } 
        })
        .then(result => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            // Reset the form and editing mode
            resetForm();
            setIsEditing(false);
            setEditedEntry(null);
            // Refresh the list of QR Code Keys
            fetchData();
            // Show success message
            setSuccessMessage('QR Code Key deleted successfully');
            setShowSuccessAlert(true);
        })
        .catch(error => {
            setDeletingIds(prevDeletingIds => {
                const newDeletingIds = new Set(prevDeletingIds);
                newDeletingIds.delete(id);
                return newDeletingIds;
            });
            // Show error message to user
            setErrorMessage(error.response?.data || 'Failed to delete QR Code Key');
            setShowAlert(true);
            console.error('Error deleting QR Code Key:', error);
        });
    };

    const resetForm = () => {
        setForm({
            name: '',
            responderAliasId: null,
            internalName: '',
            mobileNumber: '',
            startDate: '',
            endDate: '',
            timeOfDayStart: '',
            timeOfDayEnd: '',
            active: true,
            autoDeleteOnExpiry: false,
            overrideTenantIds: [],
            linkedUserId: null
        });
        setSendImmediately(true);
        setEnableDateRestriction(false);
        setEnableTimeRestriction(false);
        setRestrictionsExpanded(false);
        setSelectedOverrideTenants([]);
        setKioskRestrictionsExpanded(false);
        setQrCodeType('standard');
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        resetForm();
    };

    const handleDialogOpen = (entry) => {
        if (entry) {
            handleEditClick(entry);
        } else {
            resetForm();
            setIsEditing(false);
            setEditedEntry(null);
            setDialogOpen(true);
        }
    };

    const getRestrictionChip = (row) => {
        const hasTimeRestriction = row.timeOfDayStart && row.timeOfDayEnd;
        const hasDateRestriction = row.startDate || row.endDate;
        
        if (!hasTimeRestriction && !hasDateRestriction) return null;

        // Check if qrCodeKey is inactive due to dates
        const isInactive = row.startDate || row.endDate ? !isQrCodeKeyActive(row.startDate, row.endDate) : false;

        let label = [];
        if (hasDateRestriction) label.push('Date');
        if (hasTimeRestriction) label.push('Time');
        
        // Extract time from UTC string (format: HH:mm)
        const formatTime = (timeString) => timeString.split('T')[1].substring(0, 5);
        
        // Build detailed tooltip text
        let tooltipText = isInactive 
            ? "This key is currently inactive due to date restrictions"
            : "This key is currently active with the following restrictions:";
        
        if (hasDateRestriction) {
            tooltipText += `\nDates: ${row.startDate ? `from ${new Date(row.startDate).toLocaleDateString()}` : 'no start date'} ${row.endDate ? `until ${new Date(row.endDate).toLocaleDateString()}` : 'no end date'}`;
        }
        if (hasTimeRestriction) {
            tooltipText += `\nTime: ${formatTime(row.timeOfDayStart)} to ${formatTime(row.timeOfDayEnd)} daily`;
        }

        return (
            <Tooltip title={tooltipText}>
                <Chip
                    icon={<AccessTime />}
                    label={`${label.join(' & ')} Restricted${isInactive ? ' (Inactive)' : ''}`}
                    color={isInactive ? "error" : "success"}
                    size="small"
                    variant="outlined"
                />
            </Tooltip>
        );
    };

    const handleMenuOpen = (event, row) => {
        event.stopPropagation(); // Prevent event bubbling
        setAnchorEl(event.currentTarget);
        setSelectedRow(row);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedRow(null);
    };

    const columns = [
        { 
            field: "name", 
            headerName: "Welcome Name", 
            flex: 1,
            renderCell: (params) => {
                const isUserLinked = !!params.row.linkedUserId;
                const linkedUser = isUserLinked ? allUsers.find(u => u.id === params.row.linkedUserId) : null;
                
                return (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Tooltip title={params.row.active ? "Active" : "Inactive"}>
                            <FiberManualRecord 
                                sx={{ 
                                    color: params.row.active ? 'success.main' : 'error.main',
                                    fontSize: '0.8rem'
                                }} 
                            />
                        </Tooltip>
                        {isUserLinked ? (
                            <Stack>
                                <Typography>{linkedUser ? `${linkedUser.first_name} ${linkedUser.last_name}` : 'Loading...'}</Typography>
                                <Typography variant="caption" color="textSecondary">User Linked QR Code</Typography>
                            </Stack>
                        ) : (
                            <Stack>
                                <Typography>{params.row.name}</Typography>
                                {params.row.internalName && (
                                    <Typography variant="caption" color="textSecondary">
                                        Internal: {params.row.internalName}
                                    </Typography>
                                )}
                            </Stack>
                        )}
                    </Stack>
                );
            }
        },
        { 
            field: "mobileNumber", 
            headerName: "Mobile Number", 
            flex: 1,
            renderCell: (params) => {
                return params.row.linkedUserId ? null : params.row.mobileNumber;
            }
        },
        ...(showAliases ? [{
            field: "responderAlias", 
            headerName: "Alias", 
            flex: 1,
            valueGetter: (params) => {
                if (params.row.linkedUserId) return null;
                const alias = responderAliases.find(alias => alias.id === params.row.responderAliasId);
                return alias ? alias.alias : '';
            }
        }] : []),
        {
            field: 'restrictions',
            headerName: 'Restrictions',
            width: 200,
            renderCell: (params) => getRestrictionChip(params.row)
        },
        {
            field: "actions",
            headerName: "Actions",
            width: 70,
            renderCell: (params) => (
                <Box>
                    <IconButton
                        onClick={(e) => handleMenuOpen(e, params.row)}
                        size="small"
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedRow?.id === params.row.id}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        {!params.row.linkedUserId && (
                            <MenuItem 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleSendKeyClick(params.row);
                                    handleMenuClose();
                                }}
                            >
                                <ListItemIcon>
                                    <SendIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                    {params.row.keySent ? 'Resend Key' : 'Send Key'}
                                </ListItemText>
                            </MenuItem>
                        )}
                        <MenuItem 
                            onClick={(e) => {
                                e.stopPropagation();
                                handleEditClick(params.row);
                                handleMenuClose();
                            }}
                        >
                            <ListItemIcon>
                                <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Edit</ListItemText>
                        </MenuItem>
                        <MenuItem 
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(params.row.id);
                                handleMenuClose();
                            }}
                            disabled={deletingIds.has(params.row.id)}
                        >
                            <ListItemIcon>
                                {deletingIds.has(params.row.id) ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <DeleteIcon fontSize="small" />
                                )}
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                        <MenuItem 
                            onClick={(e) => {
                                e.stopPropagation();
                                handleViewLogs(params.row);
                                handleMenuClose();
                            }}
                        >
                            <ListItemIcon>
                                <HistoryIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>View Logs</ListItemText>
                        </MenuItem>
                    </Menu>
                </Box>
            )
        },
    ];

   

    const isQrCodeKeyActive = (startDate, endDate) => {
        if (!startDate && !endDate) return true;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
    
        if (startDate && new Date(startDate) > today) return false;
        if (endDate) {
            const endOfDay = new Date(endDate);
            endOfDay.setHours(23, 59, 59, 999);
            if (today > endOfDay) return false;
        }
        return true;
    };
    
    // Mobile view component
    const MobileView = ({ rows }) => (
        <Stack spacing={2}>
            {rows.map((row) => (
                <Card key={row.id}>
                    <CardContent>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography variant="body1" color="text.secondary">
                                {row.name}
                            </Typography>
                            <Tooltip title={row.active ? "Active" : "Inactive"}>
                                <FiberManualRecord 
                                    sx={{ 
                                        color: row.active ? 'success.main' : 'error.main',
                                        fontSize: '1rem'
                                    }} 
                                />
                            </Tooltip>
                        </Stack>

                        {/* Details */}
                        {row.internalName && (
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                Internal: {row.internalName}
                            </Typography>
                        )}
                        {showAliases && row.responderAliasId && (
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                Alias: {responderAliases.find(alias => alias.id === row.responderAliasId)?.alias}
                            </Typography>
                        )}

                        {/* Restrictions */}
                        {(row.startDate || row.endDate || row.timeOfDayStart || row.timeOfDayEnd) && (
                            <Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
                                {(row.startDate || row.endDate) && (
                                    <Tooltip title="Has date restrictions">
                                        <Chip 
                                            icon={<CalendarMonth />} 
                                            size="small" 
                                            label="Date"
                                            color="primary"
                                            variant="outlined"
                                        />
                                    </Tooltip>
                                )}
                                {(row.timeOfDayStart || row.timeOfDayEnd) && (
                                    <Tooltip title="Has time restrictions">
                                        <Chip 
                                            icon={<AccessTime />} 
                                            size="small" 
                                            label="Time"
                                            color="primary"
                                            variant="outlined"
                                        />
                                    </Tooltip>
                                )}
                            </Stack>
                        )}

                        {/* Actions */}
                        <Stack direction="row" spacing={1}>
                            <Button 
                                onClick={() => handleEditClick(row)} 
                                variant="contained" 
                                color="primary"
                                size="small"
                            >
                                Edit
                            </Button>
                            <Button
                                onClick={() => handleDelete(row.id)}
                                variant="outlined"
                                color="secondary"
                                disabled={deletingIds.has(row.id)}
                                size="small"
                            >
                                {deletingIds.has(row.id) ? <CircularProgress size={24} /> : 'Delete'}
                            </Button>
                            {!row.linkedUserId && (  // Only show Send Key button if not linked to user
                                <Button
                                    onClick={() => handleSendKeyClick(row)}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                >
                                    Send Key
                                </Button>
                            )}
                        </Stack>

                        {row.linkedUserId && (
                            <Alert severity="info" sx={{ mt: 1 }}>
                                This QR code is linked to a user account and is managed automatically
                            </Alert>
                        )}

                        {getRestrictionChip(row) && (
                            <Box sx={{ mt: 1 }}>
                                {getRestrictionChip(row)}
                            </Box>
                        )}

                        {row.mobileNumber && (
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                Mobile: {row.mobileNumber}
                            </Typography>
                        )}

                        <Typography variant="body2" sx={{ mt: 1 }}>
                            Key Sent: {row.keySent ? new Date(row.keySent).toLocaleString() : 'Not sent'}
                        </Typography>

                        {row.securityLevel && (
                            <Typography variant="body2" sx={{ mt: 1 }}>
                                Security Level: {row.securityLevel}
                            </Typography>
                        )}
                    </CardContent>
                </Card>
            ))}
        </Stack>
    );

    // Add handler for send key click
    const handleSendKeyClick = (row) => {
        setSelectedKeyToSend(row);
        setSendKeyDialogOpen(true);
    };

    // Add handler for sending key
    const handleSendKey = async () => {
        setSendingKey(true);
        try {
            const response = await axios.post(`/api/qr-code-keys/${selectedKeyToSend.id}/send`, {}, {
                params: { tenantId: sharingMasterTenantID || globalTenantId }
            });
            if (response.data.success) {
                setSuccessMessage('Key sent successfully');
                setShowSuccessAlert(true);
                fetchData(); // Refresh the grid to show updated keySent timestamp
                setSendKeyDialogOpen(false); // Close dialog after successful send
            }
        } catch (error) {
            setErrorMessage(error.response?.data || 'Failed to send key');
            setShowAlert(true);
        } finally {
            setTimeout(() => setSendingKey(false), 300); // Reset loading state after everything else
        }
    };

    // Add send key dialog
    const SendKeyDialog = () => {
        if (!selectedKeyToSend) return null;

        return (
            <Dialog open={sendKeyDialogOpen} onClose={() => setSendKeyDialogOpen(false)}>
                <DialogTitle>Send QR Code Key</DialogTitle>
                <DialogContent>
                    <Typography sx={{ mb: 2 }}>
                        Are you sure you want to send a QR code key to {selectedKeyToSend.name} ({selectedKeyToSend.mobileNumber})?
                    </Typography>
                    {selectedKeyToSend.keySent && (
                        <Alert severity="warning" sx={{ mb: 2 }}>
                            A key has already been sent on {new Date(selectedKeyToSend.keySent).toLocaleString()}. 
                            If you generate a new key and send a new link, the previous link will no longer be valid.
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setSendKeyDialogOpen(false)}
                        disabled={sendingKey}
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleSendKey}
                        variant="contained" 
                        color="primary"
                        disabled={sendingKey}
                    >
                        {sendingKey ? <CircularProgress size={24} /> : 'Send Key'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const fetchQRStatus = () => {
        axios.get('/api/tenants-qrcodekeys-status', { params: { tenantId: globalTenantId } })
        .then(response => {
            setQREnabled(response.data.SettingEnableQRCodeKeys);
        })
        .catch(error => console.error('Error fetching QR Code Keys status:', error));
    };

    const handleViewLogs = async (qrCodeKey) => {
        //console.log('handleViewLogs', qrCodeKey);
        setSelectedQrCodeKey(qrCodeKey);
        setLogDialogOpen(true);
    };

    const fetchLogs = async () => {
        if (!selectedQrCodeKey?.id) return;
        

        setLogLoading(true);
        try {
            
            const result = await axios.get(`/api/qr-code-keys/${selectedQrCodeKey.id}/logs`, {
                params: {
                    tenantId: sharingMasterTenantID || globalTenantId,
                    page: logPaginationModel.page,
                    pageSize: logPaginationModel.pageSize,
                    sortColumn: logSortModel[0]?.field || 'createdAt',
                    sortDirection: logSortModel[0]?.sort || 'desc',
                    search: logSearchTerm
                }
            });
            
            setLogs(result.data.rows || []);
            setLogCount(result.data.count || 0);
        } catch (error) {
            console.error('Error fetching logs:', error);
            setErrorMessage('Failed to fetch access logs');
            setShowAlert(true);
            setLogs([]);
            setLogCount(0);
        } finally {
            setLogLoading(false);
        }
    };

    const getChangedFields = (keyData) => {
        if (!keyData) return '';
        
        try {
            // Handle case where keyData is already an object
            const data = typeof keyData === 'object' ? keyData : JSON.parse(keyData);
            
            // Create a readable list of changes
            const changes = Object.entries(data)
                .map(([key, value]) => {
                    // Skip null/undefined values and empty objects
                    if (value === null || value === undefined || 
                        (typeof value === 'object' && Object.keys(value).length === 0)) {
                        return null;
                    }
                    
                    // Format dates
                    if (key.includes('Date') && value) {
                        value = new Date(value).toLocaleDateString();
                    }
                    
                    // Format boolean values
                    if (typeof value === 'boolean') {
                        value = value ? 'Yes' : 'No';
                    }

                    // Format arrays
                    if (Array.isArray(value)) {
                        value = value.join(', ');
                    }

                    // Format objects (for nested data)
                    if (typeof value === 'object') {
                        value = JSON.stringify(value);
                    }

                    // Make the key more readable
                    const readableKey = key
                        .replace(/([A-Z])/g, ' $1') // Add space before capital letters
                        .replace(/^./, str => str.toUpperCase()) // Capitalize first letter
                        .trim(); // Remove any extra spaces

                    return `${readableKey}: ${value}`;
                })
                .filter(change => change !== null); // Remove null entries

            return changes.join('; ');
        } catch (e) {
            console.warn('Error parsing keyData:', e);
            // If parsing fails, try to return a readable string
            return typeof keyData === 'string' ? keyData : JSON.stringify(keyData);
        }
    };

    const logColumns = [
        {
            field: "createdAt",
            headerName: "Date/Time",
            flex: 0.2,
            valueGetter: (params) => {
                const date = new Date(params.value);
                return date.toLocaleString();
            },
        },
        {
            field: "eventType",
            headerName: "Event",
            flex: 0.15,
        },
        {
            field: "success",
            headerName: "Status",
            flex: 0.1,
            renderCell: (params) => (
                <Chip 
                    label={params.value ? "Success" : "Failed"}
                    color={params.value ? "success" : "error"}
                    size="small"
                />
            ),
        },
        {
            field: "keyData",
            headerName: "Details",
            flex: 0.35,
            renderCell: (params) => {
                if (!params.value) return '';
                
                try {
                    const payload = params.value ? JSON.parse(params.value) : {};
                    return (
                        <Tooltip title={<pre>{JSON.stringify(payload, null, 2)}</pre>}>
                            <Typography
                                variant="body2"
                                sx={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    width: '100%'
                                }}
                            >
                                {JSON.stringify(payload)}
                            </Typography>
                        </Tooltip>
                    );
                } catch (e) {
                    return params.value || '';
                }
            }
        },
        {
            field: "failureReason",
            headerName: "Error",
            flex: 0.2,
        },
        {
            field: "user",
            headerName: "User",
            flex: 0.2,
            valueGetter: (params) => {
                const user = params.row.User;
                return user ? `${user.first_name} ${user.last_name}` : '';
            },
        },
    ];

    // Add handler for search input changes with debounce
    const handleLogSearchChange = (event) => {
        const value = event.target.value;
        setLogSearchTerm(value);
        
        // Clear any existing timeout
        if (logSearchTimeout) {
            clearTimeout(logSearchTimeout);
        }
        
        // Set new timeout
        const timeoutId = setTimeout(() => {
            // Reset to first page when searching
            setLogPaginationModel(prev => ({
                ...prev,
                page: 0
            }));
            fetchLogs();
        }, 500);
        
        setLogSearchTimeout(timeoutId);
    };

    // Update useEffect to refetch when search term changes
    useEffect(() => {
        if (logDialogOpen && selectedQrCodeKey) {
            
            fetchLogs();
        }
    }, [logDialogOpen, selectedQrCodeKey, logPaginationModel, logSortModel]);

    // Add export function
    const handleExportLogs = async () => {
        try {
            // Get the current URL parameters
            const params = new URLSearchParams(window.location.search);
            const tenantId = sharingMasterTenantID || globalTenantId;

            const response = await axios.get(
                `/api/qr-code-keys/${selectedQrCodeKey.id}/logs/export`,
                {
                    responseType: 'blob',
                    params: { tenantId }, // Add tenantId parameter
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            // Create download link
            const blob = new Blob([response.data], { 
                type: 'text/csv;charset=utf-8;' 
            });
            
            // Check if the blob contains HTML (indicating an error)
            const reader = new FileReader();
            reader.onload = function() {
                if (reader.result.includes('<!DOCTYPE html>')) {
                    setErrorMessage('Authentication error. Please try again.');
                    setShowAlert(true);
                    return;
                }
                
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `qr-code-logs-${selectedQrCodeKey.name}.csv`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            };
            reader.readAsText(blob);

        } catch (error) {
            console.error('Export error:', error);
            setErrorMessage('Failed to export logs. Please try again.');
            setShowAlert(true);
        }
    };

    const handleExport = async () => {
        try {
            const response = await axios.get(
                '/api/qr-code-keys/export',
                {
                    responseType: 'blob',
                    params: { 
                        tenantId: sharingMasterTenantID || globalTenantId 
                    }
                }
            );

            // Create download link
            const blob = new Blob([response.data], { 
                type: 'text/csv;charset=utf-8;' 
            });
            
            // Check if the blob contains HTML (indicating an error)
            const reader = new FileReader();
            reader.onload = function() {
                if (reader.result.includes('<!DOCTYPE html>')) {
                    setErrorMessage('Authentication error. Please try again.');
                    setShowAlert(true);
                    return;
                }
                
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'qr-code-keys.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            };
            reader.readAsText(blob);

        } catch (error) {
            console.error('Export error:', error);
            setErrorMessage('Failed to export QR Code Keys. Please try again.');
            setShowAlert(true);
        }
    };

    const fetchTenants = async () => {
        try {
            const response = await axios.get('/api/kiosks', {
                params: { 
                    tenantId: sharingMasterTenantID || globalTenantId,
                    adminOnly: true 
                }
            });
            setAllTenants(response.data);
        } catch (error) {
            console.error('Error fetching tenants:', error);
            setErrorMessage('Failed to fetch tenants');
            setShowAlert(true);
        }
    };

    useEffect(() => {
        const fetchAccessibleKiosks = async () => {
            if (!globalTenantId) return;
            
            try {
                // Pass 0 if security level is blank/null/undefined
                const level = form.securityLevel ? parseInt(form.securityLevel) : 0;
                
                const response = await axios.get(`/api/tenants-by-security-level/${level}`, {
                    params: { tenantId: globalTenantId }
                });
                setAccessibleKiosks(response.data);
            } catch (error) {
                console.error('Error fetching accessible kiosks:', error);
                setAccessibleKiosks([]);
            }
        };

        fetchAccessibleKiosks();
    }, [form.securityLevel, globalTenantId]);

    return (
        <Layout title='QR Code Keys'>   
            {qrEnabled === false && (
                <Alert severity="warning" sx={{ mb: 2 }}>
                    <AlertTitle>QR Code Keys are currently disabled</AlertTitle>
                    {sharingMasterTenantID && globalTenantId !== sharingMasterTenantID ? (
                        `QR Code Keys are disabled on this kiosk. The QR Code Keys shared with ${sharingMasterTenantName} will not be used.`
                    ) : (
                        "QR Code Keys are disabled for this kiosk. Only kiosks with QR Code Keys enabled can use them."
                    )}
                </Alert>
            )}

            {showAccessWarning && (
                <Alert severity="warning" sx={{ mb: 2 }}>
                    <AlertTitle>Access Restricted</AlertTitle>
                    {accessWarningMessage}
                </Alert>
            )}

            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Tooltip title={
                        <Typography>
                            When this option is selected, the QR Code Keys listed on this screen is the master list. All other devices then use these QR Code Keys. 
                            {shareQrCodeKeys && globalTenantId != sharingMasterTenantID ? 'This option is disabled because this is not the device sharing QR Code Keys.' : ''}
                        </Typography>
                    }>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={shareQrCodeKeys}
                                    onChange={handleShareQrCodeKeyChange}
                                    name="shareQrCodeKeys"
                                    color="primary"
                                    disabled={shareQrCodeKeys && globalTenantId != sharingMasterTenantID}
                                />
                            }
                            label={'Share QR Code Keys across all devices ' + 
                            (shareQrCodeKeys && globalTenantId != sharingMasterTenantID ? `(keys are shared with ${sharingMasterTenantName})` : '')}
                        />
                    </Tooltip>                
                </Grid>
                <Grid item xs={12} md={2}>
                    <Button 
                        onClick={handleExport}
                        variant="outlined" 
                        color="primary"
                        fullWidth
                        startIcon={<DownloadIcon />}
                        disabled={showAccessWarning}  // Disable export when access is restricted
                    >
                        Export
                    </Button>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Button 
                        onClick={() => handleDialogOpen(null)} 
                        variant="contained" 
                        color="primary"
                        fullWidth
                        disabled={showAccessWarning}  // Disable add button when access is restricted
                    >
                        Add QR Code Key
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Search names..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                </Grid>
            </Grid>

            {/* Form to add QR Code Key */}
            <Dialog 
                open={dialogOpen} 
                onClose={handleDialogClose} 
                aria-labelledby="form-dialog-title" 
                maxWidth='md'
                fullWidth
            >
                <form onSubmit={isEditing ? handleUpdateSubmit : handleAddSubmit}>
                    <DialogTitle id="form-dialog-title">
                        {isEditing ? 'Edit QR Code Key' : 'Add QR Code Key'}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            {/* Left Column - Basic Information */}
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    
                                    <Grid item xs={12}>
                                        
                                            <FormControl component="fieldset">
                                                <Grid container direction="row" spacing={2}>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            disabled={isEditing}
                                                            value="standard"
                                                            control={
                                                                <Radio
                                                                    checked={qrCodeType === 'standard'}
                                                                    onChange={(e) => {
                                                                        setQrCodeType('standard');
                                                                        setForm(prev => ({ ...prev, linkedUserId: null }));
                                                                    }}
                                                                />
                                                            }
                                                            label="Enter Details"
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            disabled={isEditing}
                                                            value="user"
                                                            control={
                                                                <Radio
                                                                    checked={qrCodeType === 'user'}
                                                                    onChange={(e) => setQrCodeType('user')}
                                                                />
                                                            }
                                                            label="Link to User"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </FormControl>
                                        
                                        
                                        {/* Show User Selection only when 'Link to User' is selected */}
                                        {(qrCodeType === 'user') && (
                                            <FormControl fullWidth>
                                                <InputLabel>Link to User</InputLabel>
                                                <Select
                                                    value={form.linkedUserId || ''}
                                                    onChange={handleInputChange}
                                                    name="linkedUserId"
                                                    label="Link to User (Optional)"
                                                    value={form.linkedUserId || ''}
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {allUsers.map(user => (
                                                        <MenuItem key={user.id} value={user.id}>
                                                            {`${user.first_name} ${user.last_name}`}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText>
                                                    When linked to a user, the QR Code Key is accessed through the Entrinsic Connect mobile     app.
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    </Grid>
                                    {qrCodeType === 'standard' && (
                                        <>
                                    <Grid item xs={12}>
                                        <Tooltip title="A friendly name that will show on the kiosk on successful entry after 'Welcome...'">
                                            <TextField
                                                fullWidth
                                                required
                                                label="Welcome name shown on kiosk"
                                                name="name"
                                                value={form.name}
                                                onChange={handleInputChange}
                                                variant="outlined"
                                                sx={{ mt: 1 }}
                                                disabled={form.linkedUserId}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Tooltip title="Your own name for this QR Code Key">
                                            <TextField
                                                fullWidth
                                                label="Internal Name"
                                                name="internalName"
                                                value={form.internalName}
                                                onChange={handleInputChange}
                                                variant="outlined"
                                                disabled={form.linkedUserId}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    </>
                                    )}

                                    { qrCodeType === 'standard' && showAliases && (
                                        <Grid item xs={12}>
                                            <Tooltip title="Specify which alias this QR Code Key will notify on successful entry.">
                                                <Autocomplete
                                                    options={responderAliases}
                                                    getOptionLabel={(option) => option.alias} 
                                                    value={responderAliases.find(alias => alias.id === form.responderAliasId) || null}
                                                    onChange={(event, newValue) => {
                                                        setForm(prevForm => ({
                                                            ...prevForm,
                                                            responderAliasId: newValue ? newValue.id : null 
                                                        }));
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Responder Alias"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                    disabled={form.linkedUserId}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    )}
                                    { qrCodeType === 'standard' && (
                                    <Grid item xs={12}>
                                        <Tooltip title="Mobile phone number of the person with this QR Code Key">
                                            <TextField
                                                fullWidth
                                                required
                                                label="Mobile Number"
                                                name="mobileNumber"
                                                value={form.mobileNumber}
                                                onChange={handleInputChange}
                                                variant="outlined"
                                                sx={{ mt: 1 }}
                                                disabled={form.linkedUserId}
                                            />
                                        </Tooltip>
                                    </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={form.active}
                                                    onChange={(e) => setForm(prev => ({
                                                        ...prev,
                                                        active: e.target.checked
                                                    }))}
                                                    name="active"
                                                    color="primary"
                                                />
                                            }
                                            label="Active"
                                        />
                                    </Grid>

                                    {!isEditing && qrCodeType === 'standard' && (  // Only show when adding new QR code key
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={sendImmediately}
                                                        onChange={(e) => setSendImmediately(e.target.checked)}
                                                        name="sendImmediately"
                                                    />
                                                }
                                                label="Send QR code immediately after creation"
                                            />
                                        </Grid>
                                    )}

                                    
                                </Grid>
                            </Grid>

                            {/* Right Column - Accordions */}
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Accordion 
                                            expanded={restrictionsExpanded}
                                            onChange={(e, isExpanded) => setRestrictionsExpanded(isExpanded)}
                                        >
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography>Date & Time Access Restrictions</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={enableDateRestriction}
                                                                    onChange={(e) => {
                                                                        setEnableDateRestriction(e.target.checked);
                                                                        if (!e.target.checked) {
                                                                            setForm(prev => ({
                                                                                ...prev,
                                                                                startDate: '',
                                                                                endDate: ''
                                                                            }));
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            label="Enable Date Restriction"
                                                        />
                                                    </Grid>
                                                    
                                                    {enableDateRestriction && (
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6}>
                                                                    <Tooltip title="Optional. If not set, there is no start date restriction. If set, the qrCodeKey will only be active from this date onwards.">
                                                                        <TextField
                                                                            fullWidth
                                                                            label="Start Date"
                                                                            type="date"
                                                                            name="startDate"
                                                                            value={form.startDate}
                                                                            onChange={handleInputChange}
                                                                            InputLabelProps={{ shrink: true }}
                                                                        />
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Tooltip title="Optional. If not set, there is no end date restriction. If set, the qrCodeKey will become inactive after this date.">
                                                                        <TextField
                                                                            fullWidth
                                                                            label="End Date"
                                                                            type="date"
                                                                            name="endDate"
                                                                            value={form.endDate}
                                                                            onChange={handleInputChange}
                                                                            InputLabelProps={{ shrink: true }}
                                                                        />
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={enableTimeRestriction}
                                                                    onChange={(e) => {
                                                                        setEnableTimeRestriction(e.target.checked);
                                                                        if (!e.target.checked) {
                                                                            setForm(prev => ({
                                                                                ...prev,
                                                                                timeOfDayStart: '',
                                                                                timeOfDayEnd: ''
                                                                            }));
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            label="Enable Time Restriction"
                                                        />
                                                    </Grid>

                                                    {enableTimeRestriction && (
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6}>
                                                                    <Tooltip title="The time of day from which the qrCodeKey becomes active. The qrCodeKey will not work before this time.">
                                                                        <TextField
                                                                            fullWidth
                                                                            label="Start Time"
                                                                            type="time"
                                                                            name="timeOfDayStart"
                                                                            value={form.timeOfDayStart}
                                                                            onChange={handleInputChange}
                                                                            InputLabelProps={{ shrink: true }}
                                                                        />
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Tooltip title="The time of day after which the qrCodeKey becomes inactive. The qrCodeKey will not work after this time.">
                                                                        <TextField
                                                                            fullWidth
                                                                            label="End Time"
                                                                            type="time"
                                                                            name="timeOfDayEnd"
                                                                            value={form.timeOfDayEnd}
                                                                            onChange={handleInputChange}
                                                                            InputLabelProps={{ shrink: true }}
                                                                        />
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                    {(enableDateRestriction || enableTimeRestriction) && (
                                                        <Grid item xs={12}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={form.autoDeleteOnExpiry}
                                                                        onChange={(e) => setForm(prev => ({
                                                                            ...prev,
                                                                            autoDeleteOnExpiry: e.target.checked
                                                                        }))}
                                                                        name="autoDeleteOnExpiry"
                                                                    />
                                                                }
                                                                label="Automatically delete key when it expires"
                                                            />
                                                        </Grid>
                                                    )}

                                                   
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    {shareQrCodeKeys && (
                                        <Grid item xs={12}>
                                            <Accordion expanded={kioskRestrictionsExpanded} 
                                                onChange={(e, isExpanded) => setKioskRestrictionsExpanded(isExpanded)}
                                                >
                                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                                    <Typography>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <span>Kiosk Access Control</span>
                                                        </Stack>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Tooltip title="Optional. Set a security level for this QR Code Key that allows access to kiosks with a security level equal or lower than this value. Must be a positive number.">
                                                                <TextField
                                                                    fullWidth
                                                                    label="Security Level"
                                                                    name="securityLevel"
                                                                    type="number"
                                                                    value={form.securityLevel}
                                                                    onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        setForm(prev => ({
                                                                            ...prev,
                                                                            securityLevel: value
                                                                        }));
                                                                    }}
                                                                    variant="outlined"
                                                                    inputProps={{ min: 1 }}
                                                                    error={form.securityLevel !== '' && form.securityLevel !== undefined && form.securityLevel !== null && 
                                                                        (isNaN(parseInt(form.securityLevel)) || parseInt(form.securityLevel) <= 0)}
                                                                    helperText={
                                                                        accessibleKiosks.length > 0 ?
                                                                            form.securityLevel ?
                                                                                `Provides access to: ${accessibleKiosks.map(k => k.OrganisationName).join(', ')}` :
                                                                                `With no security level set, this QR Code Key allows access to the following kiosks: ${accessibleKiosks.map(k => k.OrganisationName).join(', ')}` :
                                                                            'No kiosks accessible'
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        </Grid>
                                                    
                                                        <Grid item xs={12}>
                                                            <Autocomplete
                                                                multiple
                                                                options={allTenants}
                                                                value={selectedOverrideTenants}
                                                                onChange={(event, newValue) => {
                                                                    setSelectedOverrideTenants(newValue);
                                                                    setForm(prev => ({
                                                                        ...prev,
                                                                        overrideTenantIds: newValue.map(tenant => tenant.id)
                                                                    }));
                                                                }}
                                                                getOptionLabel={(option) => option.OrganisationName}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        label="Provide override access to specific kiosks"
                                                                        placeholder="Select kiosks"
                                                                    />
                                                                )}
                                                                renderTags={(tagValue, getTagProps) =>
                                                                    tagValue.map((option, index) => (
                                                                        <Chip
                                                                            label={option.OrganisationName}
                                                                            {...getTagProps({ index })}
                                                                            size="small"
                                                                        />
                                                                    ))
                                                                }
                                                            />
                                                            <Typography variant="caption" color="textSecondary" sx={{ mt: 1, display: 'block' }}>
                                                                If no kiosks are selected, the QR code will work on all kiosks subject to security level. 
                                                                Select specific kiosks to override access.
                                                            </Typography>
                                                            
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelEdit} color="primary" variant='contained'>
                            Cancel
                        </Button>
                        <Button type="submit" color="primary" variant='contained'>
                            {isEditing ? 
                                ( loadingAddOrUpdate ? <CircularProgress size={24} /> : 'Update') : 
                                ( loadingAddOrUpdate ? <CircularProgress size={24} /> : 'Add')
                            }
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>    
            {showWarningAlert && (
                <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={showWarningAlert}
                autoHideDuration={10000}
                message={warningMessage}
                    onClose={() => setShowWarningAlert(false)}
                >
                    <Alert 
                        severity="warning" 
                        onClose={() => setShowWarningAlert(false)}
                        sx={{ mb: 2 }}
                >
                    {warningMessage}
                </Alert>
                </Snackbar>
            )}

            {showAlert && (
                <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={showAlert}
                autoHideDuration={10000}
                message={errorMessage}
                onClose={() => setShowAlert(false)}
                >
                    <Alert severity="error" onClose={() => setShowAlert(false)}>
                    {errorMessage}
                    </Alert>
                </Snackbar>
            )}
            <Box sx={{ mt: 4 }}>
                {isMobile ? (
                    <MobileView rows={rows} />
                ) : (
                    <Box sx={{ height: gridHeight }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            paginationMode="server"
                            rowCount={rowCount}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            pageSizeOptions={[10, 25, 50, 100]}
                            loading={gridLoading}
                            disableSelectionOnClick
                            getRowHeight={() => 80}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 25, page: 0 },
                                },
                            }}
                        />
                    </Box>
                )}
            </Box>

            <SendKeyDialog />

            <Snackbar 
                open={showSuccessAlert} 
                autoHideDuration={6000} 
                onClose={() => setShowSuccessAlert(false)}
            >
                <Alert 
                    onClose={() => setShowSuccessAlert(false)} 
                    severity="success" 
                    sx={{ width: '100%' }}
                >
                    {successMessage}
                </Alert>
            </Snackbar>

            <Dialog
                open={logDialogOpen}
                onClose={() => setLogDialogOpen(false)}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>
                    Access Log - {selectedQrCodeKey?.name}
                </DialogTitle>
                <DialogContent sx={{ pb: 0 }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Search logs..."
                        value={logSearchTerm}
                        onChange={handleLogSearchChange}
                        sx={{ 
                            mb: 2,
                            mt: 1,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    top: -5
                                }
                            }
                        }}
                        placeholder="Search by event type, status, or details..."
                    />
                    <Box sx={{ height: 520, width: '100%' }}>
                        <DataGrid
                            rows={logs}
                            columns={logColumns}
                            paginationMode="server"
                            sortingMode="server"
                            rowCount={logCount}
                            paginationModel={logPaginationModel}
                            onPaginationModelChange={setLogPaginationModel}
                            sortModel={logSortModel}
                            onSortModelChange={setLogSortModel}
                            pageSizeOptions={[10, 25, 50]}
                            loading={logLoading}
                            disableSelectionOnClick
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={handleExportLogs}
                        variant="outlined"
                        color="primary"
                        startIcon={<DownloadIcon />}
                        sx={{ mr: 'auto' }} // This pushes the Close button to the right
                    >
                        Export to CSV
                    </Button>
                    <Button 
                        onClick={() => setLogDialogOpen(false)}
                        variant="contained"
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

           

        </Layout>
    );
}

export default QrCodeKeysGrid;